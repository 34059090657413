/**
 * Gridpak Beta SCSS
 *
 * Generator - http://gridpak.com/
 * Created by @simplygoodwork
 */

/* Reusable column setup */

@mixin col {
    border:0px solid rgba(0,0,0,0);
    float:left;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -moz-background-clip:padding-box !important;
    -webkit-background-clip:padding-box !important;
    background-clip:padding-box !important;
}

.col {
   @include col;
}

@mixin span($num, $gutter_pc, $gutter_px, $padding, $max_columns) {
    $one_col: (100% - ($gutter_pc * ($max_columns - 1))) / $max_columns;
    width:($one_col * $num) + ($gutter_pc * ($num - 1));
    border-left-width:$gutter_px;
    padding:$padding;
    margin-left:$gutter_pc;
}

@mixin span_first {
    margin-left:0;
}


    /* 1. 6 Column Grid 0px - 759px
    -----------------------------------------------------------------------------

    Span 1:    16.6666666667%
    Span 2:    33.3333333333%
    Span 3:    50.0%
    Span 4:    66.6666666667%
    Span 5:    83.3333333333%
    Span 6:    100%

    ----------------------------------------------------------------------------- */

  @media screen and (min-width: 0px) and (max-width: $breakpoint-sm-lower) {

        .row {
            margin-left:-20px;
            padding: 20px;
        }

        .col {
            border-left-width:20px;
            padding:0 0px;
        }

        /* --- Gridpak variables ---*/
        $max_columns: 6;
        $padding: 0 0px;
        $gutter_px: 20px;
        $gutter_pc: 0;




        /*
        Add your semantic classnames in alongside their corresponding spans here. e.g.

        .span_3,
        .my_semantic_class_name {
            ...
        }

        */
        .span_1 {
            @include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_2 {
            @include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_3 {
            @include span(3, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_4 {
            @include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_5 {
            @include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_6, .one-third, .job-details--main, .job-details--sidebar, .job-details--header, .one-half  {
            @include span_first;
            width:100%;
        }
    }

    /* 2. 12 Column Grid 760px - Infinity
    -----------------------------------------------------------------------------

    Span 1:    8.33333333333%
    Span 2:    16.6666666667%
    Span 3:    25.0%
    Span 4:    33.3333333333%
    Span 5:    41.6666666667%
    Span 6:    50.0%
    Span 7:    58.3333333333%
    Span 8:    66.6666666667%
    Span 9:    75.0%
    Span 10:    83.3333333333%
    Span 11:    91.6666666667%
    Span 12:    100%

    ----------------------------------------------------------------------------- */

  @media screen and (min-width: $breakpoint-sm) {

        .row {
            margin-left:-20px;
            overflow: hidden;
        }

        .col {
            border-left-width:20px;
            padding:0 0%;
        }

        /* --- Gridpak variables ---*/
        $max_columns: 12;
        $padding: 0 0%;
        $gutter_px: 20px;
        $gutter_pc: 0;




        .span_1 {
            @include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_2 {
            @include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_3 {
            @include span(3, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_4, .one-third, .job-details--sidebar {
            @include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_5 {
            @include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_6, .one-half {
            @include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_7 {
            @include span(7, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_8, .job-details--main {
            @include span(8, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_9 {
            @include span(9, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_10 {
            @include span(10, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_11 {
            @include span(11, $gutter_pc, $gutter_px, $padding, $max_columns);
        }
        .span_12, .job-details--header {
            @include span_first;
            width:100%;
        }

        .third-push {
            margin-left: (100% / 3);
        }
    }



// Designs include a generic grid that had no column rules,
// so that grid is defined here and layout hard coded, as it fits no logical layout rules, and packery etc won't work
.custom-grid {
    // .gutter-sizer {
    //     width: 2.6%;
    // }
    @include respond-min($breakpoint-md) {
        height: 800px;
    }
    position: relative;
    .custom-grid__item {
        margin-bottom: 13px;

        @include respond-min($breakpoint-md) {
            width: 28.3%;
        }

        h2, h3 {
            margin-bottom: 13px;
            font-size: 38px;
            line-height: 32px;
        }

        p {
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-6 {
            p {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        img {
            display: block;
            width: 100%;
            &.img-icon {
                width: 68.5%;
                margin: 20px auto;
            }
            &.img-auto {
                margin: 0 auto 15px auto;
                width: auto;
            }
        }

        a {
            &.video-modal {
                display: block;
            }
        }

        @include respond-min($breakpoint-md) {
            &--wide {
                //margin-right: 2.6%;
                width: 38.3%;
            }
            &--extra-wide {
                //margin-right: 2.6%;
                width: 59.05%;
            }
        }

        &--card {
            height: 64.18%;
            @include display(flex);
            @include align-items(stretch);
            @include flex-direction(column);

            > a {
                display: block;

            }
            .card-content {
                padding: 20px;
                flex: 1 1 auto;

                .inner {
                    position: relative;
                    top: 50%;
                    @include transform(translateY(-50%));

                    p:last-child {
                        margin-bottom: 0;
                    }
                }

                &.has_link {
                    padding: 20px 20px 70px;
                    position: relative;

                    & > a {
                        display: inline-block;
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        @include transform(translateX(-50%));

                        .btn {
                            margin-left: 0;
                        }
                        span + .btn {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        &--card-lefttext {
            h3 {
                text-align: left;
            }
            p {
                text-align: left;
            }
        }
        &--bigtext {
            p {
                font-size: 18px;
            }
        }
        &--quote {
            display: flex;
            align-items: center;
            padding: 60px 30px;
            height: 30.7%;
            position: relative;
            .quote-open {
                position: absolute;
                top: 20px;
                left: 20px;
                margin: 0 !important;
            }
            .quote-close {
                position: absolute;
                bottom: 20px;
                right: 20px;
                margin: 0 !important;
            }
        }

        &-2 {
            &.text-item {
                padding: 20px;

                h1 {
                    margin: 0;
                    font-size: 42px;
                    line-height: 0.9;

                    @include respond-min(1024px) {
                        font-size: 52px;
                        line-height: 0.8;
                    }
                }
            }

            &.theme-customblue {
                background: #143365;
                color: #fff;

                * {
                    color: #fff;
                }
            }
        }

        .custom-grid__hover {
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity 0.3s;
            font-size: 24px;
            font-family: "Roboto Slab", sans-serif;
            padding: 25px;
            text-align: center;

            &.has_link {
                padding: 25px 25px 75px;
            }

            & > p {
                width: 100%;
            }

            .inner {
                position: relative;
                top: 50%;
                @include transform(translateY(-50%));

                p:last-child {
                    margin-bottom: 0;
                }
            }

            & > a {
                display: inline-block;
                position: absolute;
                bottom: 25px;
                left: 50%;
                margin-left: -22px;

                .btn {
                    margin-left: 0;
                }
                span + .btn {
                    margin-left: 10px;
                }
            }
        }

        &:hover {
            .custom-grid__hover {
                opacity: 1;
            }
        }
    }

    @include respond-min($breakpoint-md) {
        &__item-1 {
            position: absolute;
            top: 0;
            left: 0;
        }
        &__item-2 {
            position: absolute;
            top: 0;
            left: 40.9%;

            &.text-item {
                height: 31.3%;
                padding: 10px;

                & > a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                h1 {
                    position: relative;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }
        }
        &__item-3 {
            position: absolute;
            top: 32.8%;
            left: 40.9%;
        }
        &__item-4 {
            position: absolute;
            top: 0;
            left: 71.7%;
        }
        &__item-5 {
            position: absolute;
            top: 32.8%;
            left: 0;
        }
        &__item-6 {
            position: absolute;
            top: 66.2%;
            left: 40.9%;
        }
    }

    @include respond-max($breakpoint-md-lower) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .custom-grid__item {
            position: relative;
        }

        &__item-1 {
            order: 1;
            width: 100%;
        }
        &__item-2 {
            order: 3;
            width: 100%;
        }
        &__item-3 {
            order: 4;
            width: 100%;
        }
        &__item-4 {
            order: 5;
            width: 100%;
        }
        &__item-5 {
            order: 2;
            width: 100%;
        }
        &__item-6 {
            order: 6;
            width: 100%;
        }
    }

}


.theme-white-orange {
    .custom-grid__hover {
        background: rgba($orange, 0.6);
        color: #fff;
    }
}
.theme-white-blue, .theme-white-lightblue {
    .custom-grid__hover {
        background: rgba($mediumblue, 0.9);
        color: #fff;
    }
}

.theme-green {
    .custom-grid__hover {
        background: rgba($darkblue, 0.7);
        color: #fff;
    }
}

.theme-white-red {
    .custom-grid__hover {
        background: rgba($red, 0.6);
        color: #fff;
    }
}

.grid-block {
    overflow: hidden;

    .container {
        & > p {
            margin-bottom: 43px;

            @include respond-max($breakpoint-sm-lower) {
                margin-bottom: 25px;
            }
        }
    }
}

.inline-video {
    margin-bottom: 30px;

    &:only-child {
        margin: -60px 0;
    }

    video {
        width: 100%;
    }
}

.videos {
    .video {
        a {
            display: inline-block;

            img {
                display: block;
            }
        }
    }
}
* {
    box-sizing: border-box;
}

html {
    // Set base font sixe to 10px for rem mixin
    font-size: 10px;
    height: 100%;
    width: 100%;
}

body {
    font-size: 16px;
    width: 100%;
    @include respond-max($breakpoint-md-lower) {
        overflow-x: hidden;
    }
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin-top: 0;
}

.grey-text {
    color: $midtext !important;
}

.hide-text {
    font-family: AdobeBlank !important;
}

@include respond-min($breakpoint-sm) {
    .hide-large {
        display: none !important;
    }
}
@include respond-max($breakpoint-sm-lower) {
    .hide-small {
        display: none !important;
    }
}

.screenreader-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}


.container {
    max-width: 1000px;
    margin: 0 auto;

    @include respond-max(1080px) {
        padding: 0 40px;
    }
}



.btn {
    display: inline-block;
    background-color: $mediumblue;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
        background-color: darken($mediumblue, 10%);
    }
}

.btn-jobs, .btn--jobs {
    background-color: $jobs;
    color: #fff !important;
    &:hover {
        background-color: darken($jobs, 10%);
    }
}

.btn--orange {
    background-color: $orange;
    color: #fff !important;
    &:hover {
        background-color: darken($orange, 10%);
    }
}

.btn--green {
    background-color: $green;
    color: #fff !important;
    &:hover {
        background-color: darken($green, 10%);
    }
}

.btn--blue {
    background-color: $mediumblue;
    color: #fff !important;
    &:hover {
        background-color: darken($mediumblue, 10%);
    }
}
.btn--darkblue {
    background-color: $darkblue;
    color: #fff !important;
    &:hover {
        background-color: darken($darkblue, 10%);
    }
}

.btn--lightblue {
    background-color: $lightblue;
    color: #fff !important;
    &:hover {
        background-color: darken($lightblue, 10%);
    }
}




.btn-large, .btn--large {
    padding: 14px 40px;
}

.btn-arrow {
    @include icon(-73px, 45px, 45px);
    cursor: pointer;
    margin-left: 10px;
    &:hover {
        background-color: inherit;
    }
}


body .section-end {
    position: relative;
    &--padding {
        padding-bottom: 140px;
    }
    &:after {
        display: block;
        content: " ";
        background: url(../images/section-end-1.png) no-repeat;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 44px;
        height: 86px;
        z-index: 99;
        cursor: pointer;
    }
    &--pale {
        &:after {
            background: url(../images/section-end-pale.png) no-repeat;
        }
    }
    &--orange {
        padding-bottom: 110px;
        &:after {
            display: block;
            content: " ";
            background-image: url(../images/section-end-orange.png);
            background-position: left bottom;
            width: 44px;
            height: 86px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 99;
        }
    }
    &--blue2, &--blue {
        padding-bottom: 110px;
        &:after {
            display: block;
            content: " ";
            background-image: url(../images/section-end-blue.png);
            width: 44px;
            height: 86px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 99;
        }
    }
    &--green {
        padding-bottom: 110px;
        &:after {
            display: block;
            content: " ";
            background-image: url(../images/section-end-green.png);
            background-position: left bottom;
            width: 44px;
            height: 86px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 99;
        }
    }
    &--red {
        padding-bottom: 110px;
        &:after {
            display: block;
            content: " ";
            background-image: url(../images/section-end-red.png);
            background-position: left bottom;
            width: 44px;
            height: 86px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 99;
        }
    }
}
body .section-start--orange {
    position: relative;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-orange.png) no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 152px;
        height: 56px;
        z-index: 99;
    }
}
body .section-start--red {
    position: relative;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-red.png) no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 152px;
        height: 56px;
        z-index: 99;
    }
}
body .section-start--lines {
    position: relative;
    padding-top: 100px;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-lines.png) no-repeat;
        width: 152px;
        height: 56px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 99;
    }
}
body .section-start--bluelines {
    position: relative;
    padding-top: 100px;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-bluelines.png) no-repeat;
        width: 155px;
        height: 56px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 99;
    }
}
body .section-start--greenlines {
    position: relative;
    padding-top: 100px;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-greenlines.png) no-repeat;
        width: 152px;
        height: 56px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 99;
    }
}
body .section-start--arrow {
    position: relative;
    padding-top: 100px;
    &:before {
        display: block;
        content: " ";
        background: url(../images/section-start-white-arrow.png) no-repeat;
        width: 45px;
        height: 83px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 99;
    }
}


.two-col {
    @include clearfix;
    padding-top: 20px !important;

    .two-col__column {
        @include calc(width, '(100% - 25px) / 2');
        float: left;
        clear: left;
        &:nth-child(2n) {
            margin-left: 25px;
            clear: right;
        }

        @media (max-width: 1023px) {
            width: 100%;
            float: none;
            clear: none;

            &:nth-child(2n) {
                clear: none;
                margin-left: 0;
            }
        }

        .two-col__column {
            width: 100%;
            float: none;
            margin: 0;
        }
    }

    & + .two-col {
        padding-top: 0;
    }
}


.tick-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    li {
        background: url(../images/popups/tick-orange.png) no-repeat;
        background-size: 50px auto;
        color: $midtext;
        padding-left: 80px;
        min-height: 80px;
        text-align: left;
        width: 100%;
    }

    &__content {
        padding-bottom: 20px;
        border-bottom: 1px dashed $orange;
        margin-bottom: 20px;
        width: 100%;
    }
    &--roboto {
        font-family: "Roboto Slab", sans-serif;
        .tick-list__content {
            display: flex;
            align-items: center;
            align-content: center;
            min-height: 70px;
            margin-bottom: 0;
        }
    }

    &--orange {
        .tick-list__content {
            border-color: $orange;
        }
        strong {
            color: $orange;
        }
        li {
            background-image: url(../images/popups/tick-orange.png);
        }
    }
    &--green {
        .tick-list__content {
            border-color: $green;
        }
        strong {
            color: $green;
        }
        li {
            background-image: url(../images/popups/tick-green.png);
        }
    }
    &--lightblue {
        .tick-list__content {
            border-color: $lightblue;
        }
        strong {
            color: $lightblue;
        }
        li {
            background-image: url(../images/popups/tick-lightblue.png);
        }
    }
    &--mediumblue {
        .tick-list__content {
            border-color: $mediumblue;
        }
        strong {
            color: $mediumblue;
        }
        li {
            background-image: url(../images/popups/tick-mediumblue.png);
        }
    }
    &--darkblue {
        .tick-list__content {
            border-color: $darkblue;
        }
        strong {
            color: $darkblue;
        }
        li {
            background-image: url(../images/popups/tick-blue.png);
        }
    }
    &--yellow {
        .tick-list__content {
            border-color: $jobs;
        }
        strong {
            color: $jobs;
        }
        li {
            background-image: url(../images/popups/tick-yellow.png);
        }
    }

    &-title {
        padding: 0 60px;

        &--orange {
            color: $orange !important;
        }
        &--green {
            color: $green !important;
        }
        &--lightblue {
            color: $lightblue !important;
        }
        &--mediumblue {
            color: $mediumblue !important;
        }
        &--darkblue {
            color: $darkblue !important;
        }
        &--yellow {
            color: $jobs !important;
        }
    }
}

.two-col {
    .tick-list-title {
        padding: 0;
    }
}

#to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 101;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 40px;
    text-align: center;
    background: #ccc;
    color: $lighttext;
    text-decoration: none;
    border-radius: 4px;

    @media (max-width: 840px) {
        display: none;
    }
}

//timeline.scss



/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
    text-align: left;
}


/* -------------------------------- 

Main components 

-------------------------------- */

#cd-timeline {
    position: relative;
}
#cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 1px;
    background: url(../images/vertical-green-dashes.png);
    @include respond-max($breakpoint-sm-lower) {
        display: none;
    }
}
@media only screen and (min-width: $breakpoint-sm) {

    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
    }

}

.cd-container {
    .cd-timeline-block, .cms-plugin {
        position: relative;
        @include respond-min($breakpoint-sm) {
            &:nth-child(2) {
                top: -50px;
            }
            &:nth-child(3) {
                top: -90px;
            }
            &:nth-child(4) {
                top: -120px;
            }
            &:nth-child(5) {
                top: -160px;
            }
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @media only screen and (min-width: $breakpoint-sm) {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}


@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
    }
}
@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}
.cd-timeline-content {
    position: relative;

    @include respond-min($breakpoint-sm) {
        margin-left: 60px;
        padding: 0 0 0 30px;
    }
}
.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}


@media only screen and (min-width: $breakpoint-sm) {
    .cd-timeline-content {
        margin-left: 0;
        width: 45%;
    }
    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: white;
    }

    .cd-container .cms-plugin:nth-child(even), .cd-container .cd-timeline-block:nth-child(even) {
        .cd-timeline-content {
            float: right;

            &::before {
                top: 24px;
                left: auto;
                right: 100%;
                border-color: transparent;
                border-right-color: white;
            }
            .cd-read-more {
                float: right;
            }
            .cd-date {
                left: auto;
                right: 122%;
                text-align: right;
            }
        }
    }
    .cd-timeline-content.is-hidden {
        visibility: hidden;
    }
    .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
    }
}

@media only screen and (min-width: $breakpoint-sm) {
    /* inverse bounce effect on even content blocks */
    .cd-container .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in,
    .cd-container .cms-plugin:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
    }
}
@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }


    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }


    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.cd-timeline-content {
    margin-bottom: 15px;
    padding-bottom: 46px;

    h2 {
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }

    .cd-bordered {
        padding: 1em;
    }
}

.lightgreen {
    .cd-timeline-content {
        background: url(../images/timeline/green-down.png) no-repeat bottom center;
    }
    .cd-bordered {
        border: 1px dashed $green;

        h2 {
            color: $green;
        }
    }
}

.orange {
    .cd-timeline-content {
        background: url(../images/timeline/orange-down.png) no-repeat bottom center;
    }
    .cd-bordered {
        border: 1px dashed $orange;

        h2 {
            color: $orange;
        }
    }
}

.lightblue {
    .cd-timeline-content {
        background: url(../images/timeline/blue-down.png) no-repeat bottom center;
    }
    .cd-bordered {
        border: 1px dashed $lightblue;

        h2 {
            color: $lightblue;
        }
    }
}

.yellow {
    .cd-timeline-content {
        background: url(../images/timeline/yellow-down.png) no-repeat bottom center;
    }
    .cd-bordered {
        border: 1px dashed $jobs;

        h2 {
            color: $jobs;
        }
    }
}

.grey {
    .cd-timeline-content {
        background: url(../images/timeline/grey-down.png) no-repeat bottom center;
    }
    .cd-bordered {
        border: 1px dashed #646464;

        h2 {
            color: #646464;
        }
    }
}

@include respond-max($breakpoint-sm - 1) {
    .cd-timeline-content {
        .cd-bordered {
            border-bottom: 0;
        }
    }
}

@include respond-min($breakpoint-sm) {
    .cd-timeline-content {
        padding-bottom: 0;
        width: 49.8%;
    }

    .cd-container {
        .cd-timeline-block, .cms-plugin {
            &:nth-child(odd) {
                .cd-timeline-content {
                    padding-right: 30px;
                }

                .cd-bordered {
                    border-right: 0;
                    padding: 1em 30px 1em 1em;
                    margin-right: 30px;
                }
            }

            &:nth-child(even) {
                .cd-timeline-content {
                    padding-left: 30px;
                }

                .cd-bordered {
                    border-left: 0;
                    padding: 1em 1em 1em 30px;
                    margin-left: 30px;
                }
            }
        }
    }

    .lightgreen {
        .cd-timeline-content{
            background: url(../images/timeline/green-right.png) no-repeat right center;
        }
        .cd-bordered {
            h2 {
                color: $green;
            }
        }
    }

    .orange {
        .cd-timeline-content {
            background: url(../images/timeline/orange-left.png) no-repeat left center;
        }
        .cd-bordered {
            h2 {
                color: $orange;
            }
        }
    }


    .lightblue {
        .cd-timeline-content{
            background: url(../images/timeline/blue-right.png) no-repeat right center;
        }
        .cd-bordered {
            h2 {
                color: $lightblue;
            }
        }
    }


    .yellow {
        .cd-timeline-content {
            background: url(../images/timeline/yellow-left.png) no-repeat left center;
        }
        .cd-bordered {
            h2 {
                color: $jobs;
            }
        }
    }

    .grey {
        .cd-timeline-content{
            background: url(../images/timeline/grey-right.png) no-repeat right center;
        }
        .cd-bordered {
            h2 {
                color: #646464;
            }
        }
    }
}




#employee-journey {
    padding: 35px;
    max-width: 840px;
    margin: 0 auto;
    @include clearfix;

    p, ul {
        font-family: "Roboto Slab", sans-serif;
        font-size: 19px;
        color: #646464;
        margin-bottom: 25px;
        line-height: 1.3;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        text-align: left;
        font-size: 14px;
    }

    img {
        display: block;
        max-width: 100%;
    }

    #content {
        padding: 30px;
        @include respond-min($breakpoint-sm) {
            float: left;
            padding: 0;
            width: 38.961%;

            & > img {
                margin: 65px auto 30px;
            }

        }

        p {
            font-size: 16px;
            line-height: 1.582;
            color: rgba(#646464, 0.75);
        }
    }

    #sections {
        width: 90%;
        margin: 0 auto;
        @include respond-min($breakpoint-sm) {
            float: right;
            width: 52.338%;
            margin: auto;
        }

        position: relative;

        & > img {
            width: 100%;
            position: relative;
            z-index: 1;
        }

        .js-journey-modal {
            display: block;
            position: absolute;
            z-index: 2;
            outline: none;
            background:white;
            opacity: 0.01;
            filter:alpha(opacity=1);

            &#instant-attraction {
                top: 11%;
                left: 20.5%;
                width: 44.5%;
                height: 20%;
            }
            &#join-the-team {
                top: 0;
                right: 0;
                width: 32%;
                height: 24.5%;
            }
            &#life-at-odeon {
                top: 32%;
                left: 0;
                width: 41.5%;
                height: 27.5%;
            }
            &#welcome-aboard {
                top: 35%;
                right: 0;
                width: 31%;
                height: 21%;
            }
            &#get-recognised {
                top: 59.5%;
                left: 0;
                width: 43.5%;
                height: 28.5%;
            }
            &#brand-heroes {
                top: 58%;
                left: 56%;
                width: 28.5%;
                height: 25%;
            }
            &#a-fond-farewell {
                bottom: 0;
                right: 5%;
                width: 43%;
                height: 15.5%;
            }
        }
    }

    .employee-journey-modal-wrapper {
        .mfp-container {
            padding: 0;

            .mfp-content {
                height: 100%;

                .journey-modal-content {
                    position: relative;
                    background: #FFF;
                    padding: 70px 30px;
                    width: 100%;
                    min-height: 100%;
                    margin: 0 auto;

                    video {
                        width: 100%;
                    }

                    .nav {
                        @include clearfix;
                        margin-bottom: 20px;

                        @media (min-width: 768px) {
                            position: absolute;
                            top: 280px;
                            left: 50%;
                            margin: 0;
                            max-width: 970px;
                            @include transform(translateX(-50%));
                            @include calc(width, '100% - 60px');
                        }

                        .modal-prev, .modal-next {
                            float: left;
                            margin: 0 20px;
                            width: 45px;
                            height: 45px;
                            text-indent: -9999em;
                            background: {
                                position: 0 58px;
                                repeat: no-repeat;
                                image: url(../images/popup-arrow-sprite.png);
                            }

                            &.yellow {
                                background-position: 0 -58px;
                            }
                            &.green {
                                background-position: -48px -58px;
                            }
                            &.orange {
                                background-position: -96px -58px;
                            }
                            &.darkblue {
                                background-position: -144px -58px;
                            }
                            &.red {
                                background-position: -192px -58px;
                            }
                            &.purple {
                                background-position: -240px -58px;
                            }
                            &.lightblue {
                                background-position: -288px -58px;
                            }
                        }
                        .modal-next {
                            float: right;

                            &.yellow {
                                background-position: 0 0;
                            }
                            &.green {
                                background-position: -48px 0;
                            }
                            &.orange {
                                background-position: -96px 0;
                            }
                            &.darkblue {
                                background-position: -144px 0;
                            }
                            &.red {
                                background-position: -192px 0;
                            }
                            &.purple {
                                background-position: -240px 0;
                            }
                            &.lightblue {
                                background-position: -288px 0;
                            }
                        }
                    }

                    &#instant-attraction-content {
                        h1, h2 {
                            color: #fcc002;
                        }

                        #attraction-boxes {
                            padding-bottom: 30px;
                            @include clearfix;

                            .left {
                                padding: 40px 25px;
                                margin-bottom: 20px;
                                @media (min-width: 640px) {
                                    padding: 25px;
                                    float: left;
                                    width: 30%;
                                    margin: 0 20px 0 0;
                                }
                                background: #ededed;

                                p, img {
                                    display: block;
                                    margin: 0 auto;
                                    text-transform: uppercase;
                                    font-size: 16px;

                                    @media (min-width: 640px) {
                                        position: relative;
                                        top: 50%;
                                        @include transform(translateY(-50%));
                                    }
                                }
                            }
                            .right {
                                @media (min-width: 640px) {
                                    float: left;
                                    @include calc(width, '70% - 20px');
                                }
                                padding: 25px;
                                background: #ededed;

                                h2 {
                                    margin-bottom: 16px;
                                    line-height: 1;
                                    font-size: 30px;
                                }

                                ul {
                                    list-style: none;
                                    margin: 0;
                                    padding: 0;

                                    li {
                                        @include clearfix;
                                        margin-bottom: 12px;

                                        &:last-child {
                                            margin-bottom: 0;
                                        }

                                        &:before {
                                            position: static;
                                            display: inline-block;
                                            content: "";
                                            margin-right: 10px;
                                            width: 15px;
                                            height: 25px;
                                            background: center center no-repeat url(../images/employee-journey/attraction/list-yellow.png);
                                            vertical-align: middle;
                                        }

                                        span {
                                            display: inline-block;
                                            width: calc(100% - 30px);
                                            vertical-align: middle;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &#join-the-team-content {
                        h1 {
                            color: #2cb097;
                        }
                    }
                    &#welcome-aboard-content {
                        h1, h2 {
                            color: #00539e;
                        }
                        h2.thin {
                            font-size: 32px;
                            max-width: 377px;
                            margin: 0 auto 25px;
                        }
                    }
                    &#life-at-odeon-content {
                        h1 {
                            color: #ea620a;
                        }
                    }
                    &#get-recognised-content {
                        h1 {
                            color: #e6361d;
                        }
                    }
                    &#brand-heroes-content {
                        h1 {
                            color: #603d90;
                        }
                    }
                    &#a-fond-farewell-content {
                        h1 {
                            color: #30bce4;
                        }
                    }

                    .content {
                        max-width: 800px;
                        margin: 0 auto;

                        h1 {
                            text-align: center;
                            font-size: 50px;
                            margin-bottom: 40px;
                        }

                        img {
                            margin: 0 auto 40px;
                        }

                        p {
                            max-width: 490px;
                            margin: 0 auto 25px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        .triple-images {
                            padding-bottom: 30px;
                            @include clearfix;

                            img {
                                display: block;
                                margin-bottom: 12px;

                                @media (min-width: 640px) {
                                    float: left;
                                    margin: 0 12px 0 0;
                                    @include calc(width, '(100% - 24px) / 3');

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        .double-images {
                            padding-bottom: 24px;
                            @include clearfix;

                            img {
                                display: block;
                                margin-bottom: 12px;

                                @media (min-width: 640px) {
                                    float: left;
                                    margin: 0 24px 0 0;
                                    @include calc(width, '(100% - 24px) / 2');

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }

                        .popup-video-wrapper {
                            video {
                                display: block;
                            }

                            .popup-video-play {
                                display: none;
                                width: 100%;
                                height: 30px;
                                padding: 5px 30px;
                                cursor: pointer;
                                background: {
                                    color: $white;
                                    position: left 5px center;
                                    repeat: no-repeat;
                                    size: 20px auto;
                                    image: url(../images/video-play-grey.png);
                                };
                                color: #505050;
                                font-size: 12px;
                                line-height: 20px;

                                &.stopped {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.employee-journey-modal-wrapper {
    background-color: #fff;
    opacity: 1;

    $green: #2cb097;
    $lightblue: #30bce4;
    $blue: #00539e;
    $orange: #ea620a;
    $yellow: #fcc002;
    $greytext: #646464;

    .mfp-close {
        position: fixed;
        right: 20px;
        top: 10px;

        &.cross-green {
            color: $green;
        }
        &.cross-mediumblue {
            color: $mediumblue;
        }
        &.cross-darkblue {
            color: $darkblue;
        }
        &.cross-lightblue {
            color: $lightblue;
        }
        &.cross-orange {
            color: $orange;
        }
        &.cross-yellow {
            color: $yellow;
        }
        &.cross-grey {
            color: $greytext;
        }
        &.cross-red {
            color: #e6361d;
        }
        &.cross-purple {
            color: #603d90;
        }
    }
}

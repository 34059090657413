.content-block {
    padding: 100px 30px;

    @include respond-max($breakpoint-sm-lower) {
        padding: 100px 0;
    }

    &--bottom-padding-off {
        padding-bottom: 0;
    }

    p, ul, ol {
        margin-bottom: 30px;
    }

    ul, ol {
        text-align: left;
    }

    &--centered {
        text-align: center;
    }

    .narrow-text {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        max-width: 100%;
    }
}

.rich-text-block {
    ul, ol {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;

        li {
            margin-bottom: 5px;
        }
    }
}
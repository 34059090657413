// Colors set

$brandColor: #518fcf;
$mediumblue: #518fcf;
$darkblue: #00539e;
$altdarkblue: #143365;
$lightblue: #30bce4;
$orange: #ea620a;
$green: #2cb097;
$red: #e6361d;
$jobs: #fac113;
$lightgreen: #b7ca01;
$white: #fff;

$midtext: #6f6f6e;
$lighttext: #888;


// Name the theme, so that we use friendly semantic CSS names in the HTML
$themes-names: lightblue mediumblue darkblue altdarkblue orange green red jobs lightgreen white-orange white-blue white-jobs white-green white-lightgreen white-lightblue white-darkblue white-red;
// Set the back ground colour, corresponding indexes to the names above
$themes-colors: $lightblue $mediumblue $darkblue $altdarkblue $orange $green $red $jobs $lightgreen $white $white $white $white $white $white $white $white;
// Set the headings (h1, h2) colour, corresponding indexes to the names above
$themes-headers: $white $white $white $white $white $white $white $white $white $orange $mediumblue $jobs $green $lightgreen $lightblue $darkblue $red;
// Set the text body color, corresponding indexes to the names above
$themes-text: $white $white $white $white $white $white $white $white $white $midtext $midtext $midtext $midtext $midtext $midtext $darkblue $midtext;


$breakpoint-sm: 760px;
$breakpoint-sm-lower: $breakpoint-sm - 1;
$breakpoint-md: 960px;
$breakpoint-md-lower: $breakpoint-md - 1;
$breakpoint-lg: 1200px;
$breakpoint-lg-lower: $breakpoint-lg - 1;


@mixin opensans($weight: 800, $texttransform: uppercase, $letterspacing: -0.06em) {
  font-family: "Open Sans", sans-serif;
  font-weight: $weight;
  letter-spacing: $letterspacing;
  text-transform: $texttransform;
}

%icon {
  display: inline-block;
  vertical-align: middle;
}
@mixin icon($top: 0, $height: 24px, $width: 24px) {
  @extend %icon;
  background: url(../images/sprite.png) no-repeat;
  background-position: 0 $top;
  font-family: AdobeBlank;
  height: $height;
  width: $width;
}

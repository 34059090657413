.perks-carousel {
    .slide {
        h3 {
            margin: 0;
            padding: 20px 0;
            font-size: 24px;
            text-transform : uppercase;
            line-height: 1;
        }
        .perk-detail {
            border: 1px dashed #fff;
            margin: 0 15px;
            padding: 30px 20px;

            & > img {
                display: block;
                margin: 0 auto;
                height: 95px;
            }
        }
        p {
            font-weight: 100;
            margin: 0;
            padding-bottom: 30px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
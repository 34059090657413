// Build the content block & page themes, using theme color and theme name lists in variables.scss.

// Page themes - used to create the header and nav bar themes, class applied to body tag only.

@for $i from 1 through length($themes-names) {
    .page-theme-#{nth($themes-names, $i)} {
        .header {
            background-color: nth($themes-colors, $i);
        }
        &.has-banner {
            .header {
                background-color: darken(nth($themes-colors, $i), 10%);
            }
        }
        .banner-home {
            margin-top: -1px;
            .title-your {
                background-image: url('../images/title-your-#{nth($themes-names, $i)}.png');
            }
            .title-you-bottom {
                background-image: url('../images/title-you-bottom-#{nth($themes-names, $i)}.png');
            }
            svg {
                #base {
                    fill: nth($themes-colors, $i);
                }
            }
            .banner-content {
                background: nth($themes-colors, $i);
            }
        }

        .mfp-close {
            color: nth($themes-colors, $i);
        }
    }

    .spacer {
        &.#{nth($themes-names, $i)} {
            color: nth($themes-colors, $i);
        }
    }
}

// Content Block Themes, applied to the content-block calls elements.
// Include fallback for nested themes in white background themes...

@for $i from 1 through length($themes-names) {
    .theme-#{nth($themes-names, $i)},
    .theme-white-blue .theme-#{nth($themes-names, $i)},
    .theme-white-lightblue .theme-#{nth($themes-names, $i)},
    .theme-white-orange .theme-#{nth($themes-names, $i)},
    .theme-white-jobs .theme-#{nth($themes-names, $i)} {

        background-color: nth($themes-colors, $i);
        color: nth($themes-text, $i);

        a {
            color: nth($themes-text, $i);
            text-decoration: none;
        }

        h1, h2 {
            color: nth($themes-headers, $i);
        }
        @if ( nth($themes-names, $i) == 'white-green' ) {
            .btn-arrow {
                background-position: 0 -471px;
            }
        }

        .header-themed {
            background-color: nth($themes-headers, $i);
            color: #fff;
            h1, h2 {
                color: #fff;
            }
        }

        .mfp-close {
            color: nth($themes-headers, $i);
        }

        &.job {
            .btn-jobs {
                background-color: nth($themes-headers, $i);
                color: #fff;
            }
            .job--spec {
                li {
                    border-color: nth($themes-headers, $i);
                }
            }
            a {
                color: nth($themes-headers, $i);
            }
        }

        &.quote-block {
            .quote {
                color: nth($themes-headers, $i);
            }
        }
    }
}



.story {
    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0 0 60px;
        text-align: left;
        clear: both;

        &:first-child {
            margin-top: -60px;
        }

        h1 {
            font-size: 60px;
            line-height: 0.9;
            margin-bottom: 35px;

            @include respond-max($breakpoint-sm-lower) {
                font-size: 50px;
                word-wrap: break-word;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
                text-align: center;
            }

            & + h2 {
                margin-top: -35px;

                @include respond-max($breakpoint-sm-lower) {
                    text-align: center;
                    margin-top: -20px;
                }
            }
        }
        h1, h2, h3, h4, p, ol, ul {
            text-align: left;
            color: #fff;
        }

        & + img {
            margin-top: -30px;
        }

        &-image {
            flex: 0 0 auto;
            width: 50%;
            min-height: 400px;
            background: {
                position: center center;
                repeat: no-repeat;
                size: cover;
            }

            @include respond-max($breakpoint-sm-lower) {
                width: 100%;
                order: 2;
            }

            img   {
                display: block;
                width: 100%;
                margin: 0;
            }
        }

        &-intro {
            flex: 0 0 auto;
            padding: 50px 60px 50px 30px;
            text-align: left;
            width: 50%;
            @include respond-max($breakpoint-sm-lower) {
                order: 1;
                width: 100%;
                padding: 40px 40px 15px;
            }
        }
    }


    &__content {
        img   {
            display: block;
            width: 100%;
        }
    }

}

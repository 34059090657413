.cards {
    @include respond-min($breakpoint-sm) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
            width: 30%;
        }
    }
}

.card {
    margin-bottom: 20px;

    .card-image {
        background: center center no-repeat;
        background-size: cover;

        img {
            display: block;
            width: 100%;
        }
    }
    .card-content {
        padding: 20px;

        h1, h2 {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 15px;
        }

        &--lefttext {
            h1, h2 {
                text-align: left;
                text-transform: none;
            }
            p {
                text-align: left;
            }
        }

        .inner {
            &.no-link {
                margin-bottom: 45px;
            }
        }

        .block-link {
            display: inline-block;
        }
    }

    &--white {
        background-color: #fff;
        padding: 15px;
        color: $midtext;

        .card-content {
            padding: 0;
            p {
                font-size: 14px;
            }
            h2 {
                font-size: 20px;
                line-height: 26px;
                margin-top: 18px;
                margin-bottom: 10px;
            }
        }
    }

    &--purple-text {
        .card-content h2 {
            color: #6c3f80;
        }
    }
    &--red-text {
        .card-content h2 {
            color: #de0613;
        }
    }
    &--pink-text {
        .card-content h2 {
            color: #fb7293;
        }
    }
    &--yellow-text {
        .card-content h2 {
            color: #f7b701;
        }
    }
    &--green-text {
        .card-content h2 {
            color: #00b197;
        }
    }
    &--blue-text {
        .card-content h2 {
            color: #00bee0;
        }
    }
}

// Horizontal cards that aren't necessarily in a card set (.cards)
.card {

    &--horizontal {
        display: flex;
        flex-wrap: wrap;

        .card-image {
            @include respond-min($breakpoint-sm) {
                width: 50%;
            }
            img {
                width: 100%;
            }
        }
        .card-content {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            align-content: space-around;
            justify-content: space-around;
            padding: 30px 30px;

            @include respond-min($breakpoint-sm) {
                width: 50%;
            }

            h2, p {
                width: 100%;
                display: block;
            }

            .btn-arrow {
                margin: 0 auto;
                padding: 0;
                height: 32px;
                width: 32px;
            }
        }
    }

    @include respond-max($breakpoint-sm-lower) {
        &--horizontal {
            .card-image {
                display: none;
            }
            .card-content {
                h2 {
                    order: 3;
                }
                p {
                    order: 1;
                    margin-bottom: 20px;
                }
                a.btn-arrow {
                    order: 2;
                }
            }
        }
    }
}

.owl-carousel {
    .card {
        margin: 0 10px;
    }
}

#vision-values {
    padding-bottom: 50px;

    p.intro {
        margin: 0 auto 43px;
        max-width: 740px;
        font-size: 34px;
        text-transform: uppercase;
    }

    p.outro {
        width: 90%;
        max-width: 690px;
        margin: 50px auto 0px;
    }

    .owl-theme .owl-nav {
        .owl-prev {
            left: -50px;
        }
        .owl-next {
            right: -50px;
        }
    }
}

#variety-guaranteed {
    .card-content {
        display: block;

        .inner {
            position: relative;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
}

.js-whatsright-carousel {
    .card-content {
        h2 {
            font-size: 35px;
            line-height: 40px;

            @media (max-width: 1080px) {
                font-size: 30px;
                line-height: 35px;
            }
            @media (max-width: 960px) {
                font-size: 21px;
                line-height: 25px;
            }
        }
    }
}

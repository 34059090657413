
@keyframes animate_out {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        transform: scale(100);
        opacity: 0;
    }
}

.slider-container {
    position: relative;
    z-index: 200;
}

.banner-container {
    overflow: hidden;
    @include clearfix;

    &.full-height {
        height: 515px;
        @media (min-width: 450px) {
            height: 485px;
        }
        @media (min-width: 760px) {
            @include calc(height, '100vh - 105px');
        }

        @media (min-height: 720px) {
            @include calc(height, '100vh - 105px');
        }
    }

    .banner-content {
        color: #fff;
        @include clearfix;
        position: relative;
        z-index: 100;
    }

    video, img.full-width-image {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translate(-50%, -50%);
    }
    video {
        visibility: hidden;
    }

    .tagline {
        font-family: 'Kameron', serif;
        font-size: 20px;
        width: 100%;
    }

    &.banner-home {
        .spacer {
            position: relative;
            z-index: 10;
        }
        .title-your {
            position: relative;
            z-index: 10;
            background: no-repeat center center;
            background-size: 1050px auto;
            height: 160px;
            font-family: AdobeBlank, sans-serif;
            @include respond-min($breakpoint-sm) {
                background-size: auto auto;
                height: 300px;
            }
        }
        .title-you-bottom {
            background: no-repeat top center;
            background-size: 1050px auto;
            height: 192px;
            font-family: AdobeBlank, sans-serif;
            @include respond-min($breakpoint-sm) {
                background-size: auto auto;
                height: 300px;
            }

        }
        .title-us-bottom {
            background: url(../images/title-us-bottom-red.png) no-repeat top center;
            background-size: 1050px auto;
            height: 200px;
            font-family: AdobeBlank, sans-serif;
            @include respond-min($breakpoint-sm) {
                background-size: auto auto;
                height: 330px;
            }

        }
        .banner-content {
            text-align: center;

            &--with-form {
                padding-bottom: 30px;
            }

            h1 {
                margin: 0 auto 20px auto;
                max-width: 1000px;
            }

            &--bottom {
                padding-top: 60px;
                padding-bottom: 10px;
                h1 {
                    margin-bottom: 0;
                }
            }

            .btn {
                margin: 15px 0 0;
                width: 50px;
                height: 50px;
                background-size: 110px;
                background-position: 0 -80px;
            }
        }

        .cover-image {
            display: block;
            position: absolute;
            top: 160px;
            left: 0;
            margin-left: 0;
            width: 100%;
            max-width: 700px;
            height: 105px;
            background: {
                position: top center;
                repeat: no-repeat;
                size: cover;
            }

            @include respond-min($breakpoint-sm) {
                top: 220px;
                left: 50%;
                margin-left: -350px;
                height: 175px;
            }
        }

        &.banner-image {
            background: url(../images/home-banner-1.jpg) no-repeat center center;
            background-size: cover;
        }

        &.banner-whats-right {
            background: {
                image: url(../images/whats-right-header.jpg);
                repeat: no-repeat;
                position: calc(50% - 30px) calc(0% + 50px);
                size: auto 80%;
            }

            @media screen and (min-width: 610px) {
                background: {
                    position: calc(50% - 50px) calc(0% + 40px);
                    size: contain;
                }

            }
        }

        &.banner-about {
            background: url(../images/about-header.jpg) no-repeat top center;

            @media screen and (max-width: 759px) {
                background-size: auto 100%;
                background-position: bottom -70px center;
            }
        }

        &.banner-video {
            background: transparent;
            position: relative;
            overflow: hidden;
            .animate-out {
                animation: animate_out 3s normal forwards ease-out;
            }
        }
        .banner-video-top-layer {
            z-index: 110;
            position: relative;
            height: 100%;
        }
        .banner-cta-block {
            width: 600px;
            margin-left: -300px;
            left: 50%;
            position: absolute;
            bottom: 80px;

            padding: 30px;
            text-align: center;
            text-transform: none;
            font-family: "Roboto Slab", sans-serif;
            font-size: 16px;
            line-height: 1.3;
            opacity: 0.95;
            z-index: 9;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1200px;
            margin: 60px auto 0 auto;
            .form-row {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                @include respond-min($breakpoint-sm) {
                    width: 22%;
                }
            }
            .postcode, .submit {
                text-align: center;
                width: 100%;
            }
        }

        .video-pause {
            display: none;
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 30px;
            right: 20px;
            z-index: 120;
            cursor: pointer;
            background: {
                position: center center;
                repeat: no-repeat;
                size: 50px auto;
                image: url(../images/video-pause.png);
            }

            &.paused {
                background-image: url(../images/video-play.png);
            }
        }
    }

    &.banner-journey {
        .banner-content {
            height: 100%;

            h1 {
                margin-bottom: 3%;
            }
        }

        .title-your {
            position: relative;
            z-index: 10;
            background: no-repeat center center;
            background-size: 1050px auto;
            height: 160px;
            font-family: AdobeBlank, sans-serif;
            @include respond-min($breakpoint-sm) {
                background-size: auto auto;
                height: 300px;
            }
        }
    }

    &.banner-inspire {
        background: {
            color: #fff;
            position: right center;
            repeat: no-repeat;
            size: contain;
        }
        @media (min-width: 768px) {
            background-image: url(../images/carousel/inspire.jpg);
        }


        .banner-content {
            height: 100%;
            color: $lighttext;

            @media (min-width: 768px) {
                float: left;
                width: 60%;
            }

            .inner {
                width: 100%;
                position: relative;
                @include calc(top, '50% - 45px');
                @include transform(translateY(-50%));

                @media (min-width: 760px) {
                    top: 50%;
                }

                @media (min-width: 768px) {
                    float: right;
                    max-width: 375px;
                }
            }

            h1 {
                font-size: 30px;
                line-height: 1;
                margin: 20px 40px;
                position: relative;
                text-align: center;

                @media (max-width: 480px) {
                    font-size: 34px;
                }

                @media (min-width: 768px) {
                    font-size: 38px;
                    margin: 0 0 20px;
                    text-align: left;
                }

                span {
                    color: $lightblue;
                    display: block;
                }

                &:before, &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: -10px;
                    left: -30px;
                    width: 26px;
                    height: 20px;
                    background: {
                        position: center center;
                        repeat: no-repeat;
                        size: contain;
                        image: url(../images/carousel/quote-left-grey.png);
                    }

                    @media (min-width: 768px) {
                        left: -60px;
                        width: 53px;
                        height: 41px;
                    }
                }
                &:after {
                    display: inline-block;
                    position: relative;
                    top: 9px;
                    left: 10px;
                    background-image: url(../images/carousel/quote-right-grey.png);

                    @media (min-width: 768px) {
                        right: 0;
                    }
                }
            }

            .be-inspired {
                width: 100%;
                padding: 70px 0 0;
                text-align: center;

                img:last-child {
                    margin-left: 15px;
                }
            }
        }
    }

    &.banner-edge {
        background: $darkblue;

        .banner-content {
            position: relative;
            @include calc(top, '50% - 45px');
            @include transform(translateY(-50%));

            h1 {
                line-height: 1;
                font-size: 35px;
                position: relative;

                @media (max-width: 480px) {
                    font-size: 32px;
                }
                @media (max-height: 650px) {
                    font-size: 32px;
                }

                &.upper {
                    position: relative;
                    max-width: 410px;
                    margin: 0 auto 20px;

                    @media (max-width: 767px) {
                        padding: 0 35px;
                    }
                    @media (max-height: 650px) {
                        padding: 0 35px;
                        max-width: 600px;
                    }

                    &:before {
                        display: block;
                        content: "";
                        position: absolute;
                        top: -10px;
                        left: -50px;
                        width: 45px;
                        height: 35px;
                        background: {
                            position: center center;
                            repeat: no-repeat;
                            size: contain;
                            image: url(../images/carousel/quote-left-lightblue.png);
                        }

                        @media (max-width: 767px) {
                            top: -4px;
                            left: 10px;
                            width: 26px;
                            height: 20px;
                        }
                        @media (max-height: 650px) {
                            top: -4px;
                            left: 10px;
                            width: 26px;
                            height: 20px;
                        }
                    }
                }

                span {
                    color: $lightblue;

                    &.end-quote {
                        display: inline-block;
                        position: relative;
                        top: 7px;
                        left: 10px;
                        width: 45px;
                        height: 35px;
                        background: {
                            position: center center;
                            repeat: no-repeat;
                            size: contain;
                            image: url(../images/carousel/quote-right-lightblue.png);
                        }

                        @media (max-width: 767px) {
                            top: -6px;
                            width: 26px;
                            height: 20px;
                        }
                        @media (max-height: 650px) {
                            top: -6px;
                            width: 26px;
                            height: 20px;
                        }
                    }
                }
            }

            & > img {
                display: block;
                margin: 20px auto 15px;
                max-width: 90%;

                @media (max-width: 767px) {
                    max-height: 100px;
                }
                @media (max-height: 650px) {
                    max-height: 100px;
                }
            }

            .be-inspired {
                width: 100%;
                text-align: center;

                img:last-child {
                    margin-left: 15px;
                }
            }
        }

        .tagline {
            max-width: 410px;
            margin: 0 auto;

            @media (max-height: 650px) {
                max-width: 600px;
            }
        }
    }

    &.banner-fearless {
        background: {
            color: #fcc002;
            position: right 10% center;
            repeat: no-repeat;
            size: contain;
        }
        @media (min-width: 768px) {
            background-image: url(../images/carousel/fearless.png);
        }
        @media (max-width: 768px) {
            background-size: auto;
        }

        .banner-content {
            height: 100%;
            width: 55%;

            @media (max-width: 768px) {
                width: 70%;
            }
            @media (max-width: 767px) {
                width: 100%;
            }

            .inner {
                width: 100%;
                position: relative;
                @include calc(top, '50% - 45px');
                @include transform(translateY(-50%));

                @media (min-width: 760px) {
                    top: 50%;
                }

                @media (min-width: 768px) {
                    float: right;
                    max-width: 370px;
                }
            }

            h1 {
                line-height: 1;
                font-size: 35px;
                position: relative;

                @media (max-width: 480px) {
                    font-size: 32px;
                }

                @media (max-width: 767px) {
                    padding: 0 40px;
                    text-align: center;
                }

                span {
                    display: block;
                    color: #e6361d;

                    &.end-quote {
                        display: inline-block;
                        position: relative;
                        top: 9px;
                        left: 10px;
                        width: 45px;
                        height: 35px;
                        background: {
                            position: center center;
                            repeat: no-repeat;
                            size: contain;
                            image: url(../images/carousel/quote-right-red.png);
                        }

                        @media (max-width: 767px) {
                            width: 26px;
                            height: 20px;
                            position: absolute;
                            bottom: 0;
                            right: 10px;
                            top: auto;
                        }
                    }
                }

                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: -13px;
                    left: -50px;
                    width: 45px;
                    height: 35px;
                    background: {
                        position: center center;
                        repeat: no-repeat;
                        size: contain;
                        image: url(../images/carousel/quote-left-red.png);
                    }

                    @media (max-width: 767px) {
                        top: -10px;
                        left: 10px;
                        width: 26px;
                        height: 20px;
                    }
                }
            }

            .be-fearless {
                padding-top: 40px;

                @media (max-width: 767px) {
                    text-align: center;
                }

                img:last-child {
                    margin-left: 15px;
                }
            }
        }
    }

    #video-overlay {
        display: none;
        width: 80%;
        max-width: 480px;
        position: absolute;
        left: 50%;
        bottom: 95px;
        background-color: #518ecf;
        background-color: rgba(#518ecf, 0.95);
        color: #fff;
        text-align: center;
        padding: 45px 40px 30px;
        @include transform(translateX(-50%));
        @include clearfix;

        p {
            line-height: 25px;
            font-family: "Roboto Slab", sans-serif;
            margin-bottom: 25px;
        }

        .btn {
            margin: 0;
        }
    }
}

#home-page {
    .banner-container {
        overflow: hidden;
        @include clearfix;

        &.full-height {
            height: 610px;
            padding-top: 96px;
            box-sizing: border-box;

            @media (min-width: 450px) {
                height: 580px;
            }
            @media (min-width: 760px) {
                @include calc(height, '100vh');
            }
            @media (min-height: 720px) {
                @include calc(height, '100vh');
            }
        }

        &.banner-home {
            padding-top: 0;

            .spacer {
                height: 105px;
            }
        }

        &.banner-inspire {
            background: {
                position: right bottom;
                size: auto 85%;
            }
        }

        &.banner-fearless {
            background-size: auto 80%;
        }
    }
}

.header {
    font-family: "Roboto Slab", sans-serif;
    text-transform: uppercase;
}

a {
    outline: none;
    color: inherit;
}

h1, h2 {
    @include opensans(800);
}

.lead {
    font-size: 1.08em;
}

.btn {
    @include opensans(800);
}

.jobs-panel {
    h2 {
        @include font-size(3.4);
        line-height: 1;
        letter-spacing: normal;
    }
    button {
        @include opensans(700, uppercase, normal);
        font-size: 12px;
    }
    .job {
        @include font-size(1.2);
        @include opensans(700, uppercase, normal);
        h3, h4 {
            @include font-size(1.6);
            font-weight: 700;
        }
    }
    .featured, .find-out-more {
        p a {
            @include opensans(700, uppercase, normal);
            font-size: 12px;
        }
    }
    .twitter a {
        @include opensans(700, uppercase, normal);
        font-size: 24px;
    }
}


.nav-mobile {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 600;
    > ul > li {
        text-transform: uppercase;
    }
    ul ul li {
        font-weight: normal;
        text-transform: none;
    }
}

.banner-home {
    h1 {
        font-size: 42px;
        line-height: 46px;
        @include respond-max($breakpoint-sm-lower) {
            font-size: 30px;
            line-height: 36px;
        }
    }
    svg, .banner-content h1, .banner-content h2, .banner-content p {
        @include opensans(800, uppercase);
    }
    svg #title {
        letter-spacing: -0.05em;
        font-size: 200px;
    }
}



.content-block {
    h1, h2 {
        font-size: 52px;
        line-height: 48px;
        @include respond-max($breakpoint-sm-lower) {
            font-size: 30px;
            line-height: 36px;
        }
    }
    p {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
    }
    .sub-text {
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
    a {
        font-family: "Open Sans", sans-serif;
    }
}

.card {
    h1, h2, h3 {
        font-size: 40px;
    }
    a {
        @include opensans(600, uppercase);
    }
}

.cards {
    .card {
        h1, h2 {
            font-size: 26px;
            letter-spacing: 0.02em;
            line-height: 30px;
        }
        p {
            font-size: 12px;
        }

    }
}


form {
    @include opensans(600, uppercase);
    label {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        text-transform: none;
    }
}

.job {
    font-family: "Roboto Slab", sans-serif;
    time {
        font-size: 14px;
    }
    h1, h2 {
        font-size: 25px;
        letter-spacing: 0.02em;
    }
    .btn {
        font-size: 23px;
        letter-spacing: 0.02em;
    }
}

.job-details {
    .header-themed {
        h1, h2 {
            font-size: 52px;
        }
    }
}

.custom-grid {
    .custom-grid__item--card {
        h3 {
            @include opensans(800, uppercase);
            font-size: 32px;
            text-transform: uppercase;
        }
    }
}

.footer {
    font-family: "Roboto Slab", sans-serif;
    font-size: 14px;
}

#cd-timeline .cd-timeline-block {
    font-family: "Roboto Slab", sans-serif;
    text-transform: none;
    h2 {
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: none;
        line-height: 1.4;
    }
    p {
        font-size: 14px;
    }
}


h1,
h2,
h3 {
    @include respond-max(440px) {
        word-wrap: break-word;
        overflow-wrap: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }
}

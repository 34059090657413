.header {
    color: #fff;
    padding: 38px 38px 10px 38px;
    position: relative;
    z-index: 201;
    height: 105px;

    .site-banner {
        @extend %clearfix;
    }
    .logo-odeon {
        background: url(../images/sprite.png) no-repeat 0 -861px;
        display: block;
        font-family: AdobeBlank, sans-serif;
        height: 57px;
        float: left;
        width: 76px;
        margin-top: -20px
    }
    .icon-search {
        @include icon(0);
    }

    nav, .nav-triggers {
        ul {
            list-style-type: none;
            margin: 0;
            @extend %clearfix;
            li {
                float: left;
            }
        }
    }
    .nav-triggers {
        float: right;
        li {
            cursor: pointer;
            margin-left: 20px;
        }
    }
    .burger {
        display: block;
        span {
            background-color: #fff;
            display: block;
            margin-bottom: 4px;
            height: 4px;
            width: 30px;
        }
        div {
            position: absolute;
        }
    }

    .nav-panel {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        min-height: calc(100vh - 105px);

        @include respond-max($breakpoint-sm-lower) {
            overflow-x: hidden;
        }

        z-index: 999;

        &.js-active {
            top: 105px;
            bottom: auto;
        }
        a {
            color: #fff;
            text-decoration: none;
            .btn-arrow {
                margin-left: 10px;
            }
        }
    }
    .main-nav-panel {
        background-color: $red;
        display: flex;
        /* justify-content: space-around; */
        align-items: center;
        width: 100%;
        > ul {
            width: 750px;
            margin: 0 auto;
            padding: 0;
            @include clearfix;
            > li {
                width: 25%;
                padding: 0 10px;
                text-align: center;

                &.has-sub-nav {
                    > a {
                        display: inline-block;
                        padding-bottom: 20px;
                        border-bottom: 1px dashed #fff;
                        margin-bottom: 20px;
                    }
                }

                img {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 20px;
                }
                .sub-nav {
                    display: block;
                    padding: 0;
                    margin: 0;
                    li {
                        display: block;
                        float: none;
                        text-transform: none;
                        margin-bottom: 10px;
                    }

                }

            }
        }
    }
    .jobs-panel {
        background-color: $red;
        padding-top: 10px;
        @include respond-min($breakpoint-sm) {
            padding-top: 100px;
        }

        .container {
            max-width: 750px;
        }
    }
    .job-search, .whats-right, .featured {
        padding: 20px 20px 70px;
        text-align: center;
        margin-bottom: 20px;
        position: relative;

        @include respond-min($breakpoint-sm) {
            margin-bottom: 0px;
        }

        form {
            text-align: left;
        }

        .btn-container {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 15px;
            margin: 0;
            text-align: center;

            .btn-arrow {
                margin-left: 0;
            }
            span + .btn-arrow {
                margin-left: 10px;
            }
        }
    }
    .job-search {
        background-color: $jobs;
        form {


            .form-row {
                margin-bottom: 10px;
            }
            @include respond-min($breakpoint-sm) {
                border-top: 1px dashed #fff;
                .form-row {
                    margin-bottom: 0px;
                }
            }

            .bordered {
                @include respond-min($breakpoint-sm) {
                    border-bottom: 1px dashed #fff;
                    padding: 8px 0;
                }
            }
        }
    }
    .whats-right {
        background-color: $orange;
        padding-top: 50px;
        p {
            text-transform: none;
        }
    }
    .featured {
        background-color: $green;

        .jobs-listing {
            list-style-type: none;
            padding: 0;
            text-align: left;
            margin-bottom: 30px;

            @include respond-max($breakpoint-sm-lower) {
                display: none;
            }

            .job {
                border-top: 1px dashed #fff;
                position: relative;
                padding: 5px 0;
                float: none;
                &:last-child {
                    border-bottom: 1px dashed #fff;
                }
                a {
                    display: block;
                    color: #fff;
                    text-decoration: none;
                    &:after {
                        content: " ";
                        @include icon(-48px,30px,30px);
                        background-size: 66px auto;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                }
                h2, h3, h4 {
                    margin: 0;
                }
            }
        }
        p a {
            color: #fff;
            text-decoration: none;

        }
    }

    @include respond-max($breakpoint-sm-lower) {
        .whats-right, .featured {
            p {
                display: none;
            }
        }
    }

    .twitter {
        // Temporarily hide as no social plan
        visibility: hidden;

        text-align: center;
        .icon-twitter {
            display: block;
            margin: 0 auto;
            @include icon(-367px, 100px,100px);
        }
    }

    .find-out-more {
        text-align: right;
        padding-top: 105px;
    }

    @include respond-max($breakpoint-sm-lower) {
        .twitter, .find-out-more {
            display: none;
        }
    }
}

#home-page {
    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;

        &#site-header {
            &:not(.open) {
                background: none;
            }

            .site-banner {
                padding: 38px 38px 30px;
                background: rgba(#000, 0.2);
            }
        }
    }
}

.nav-mobile {
    color: $midtext;
    position: absolute;
    width: 100%;
    z-index: 999;
    display: none;

    &.js-active {
        display: block !important;
    }

    ul {
        background: #fff;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            padding: 0;
            a {
                color: $midtext;
                display: block;
                padding: 10px 38px;
                text-decoration: none;
            }
        }
    }
    > ul {
        > li {
            border-bottom: 1px solid $brandColor;
            ul {
                display: none;
                li {
                    border-bottom: 1px solid $brandColor;
                    &:last-child {
                        border: 0;
                    }
                    a {
                        background-color: #c2e4f4;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.nav-mobile, .header {
    .close {
        color: #fff;
        font-size: 46px;
        text-decoration: none;
        position: absolute;
        top: -77px;
        right: 42px;
        z-index: 999;
    }
}
.nav-mobile .close {
    top: -80px;
}
#home-page .nav-mobile {
    margin-top: 105px;
}

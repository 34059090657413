#error-page {
    text-align: center;

    #to-top {
        display: none;
    }

    &.error-404 {
        .rich-text-block {
            @include respond-min($breakpoint-sm) {
                @include calc(min-height, '100vh - 198px');
            }
        }
    }

    &.error-500 {
        .rich-text-block {
            @include calc(min-height, '100vh - 96px');
        }
    }
}

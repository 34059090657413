.quote-block {
    .quote {
        font-size: 42px;
        line-height: 1;
        font-weight: 300;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 25px;

        @media (max-width: 640px) {
            font-size: 32px;
        }
    }
    .author {
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
        font-size: 21px;
        color: $lighttext;
        margin-bottom: 60px;

        @media (max-width: 640px) {
            margin-bottom: 25px;
        }
    }
}
$green: #2cb097;
$lightblue: #30bce4;
$blue: #00539e;
$orange: #ea620a;
$yellow: #fcc002;
$greytext: #646464;

.mfp-ajax-holder {
    background: #fff;
}

// Overwrite JS plugin defaults
.inline-modal-wrap {
    background-color: #fff;
    opacity: 1;

    .mfp-close {
        position: fixed;
        right: 20px;
        top: 10px;
    }

    &.green {
        .mfp-close {
            color: $green;
        }
    }
    &.mediumblue {
        .mfp-close {
            color: $mediumblue;
        }
    }
    &.darkblue {
        .mfp-close {
            color: $darkblue;
        }
    }
    &.lightblue {
        .mfp-close {
            color: $lightblue;
        }
    }
    &.orange {
        .mfp-close {
            color: $orange;
        }
    }
    &.yellow {
        .mfp-close {
            color: $yellow;
        }
    }
    &.grey {
        .mfp-close {
            color: $greytext;
        }
    }
}
.mfp-bg {
    background-color: #fff;
    opacity: 1;
}
.mfp-iframe-holder .story .mfp-close {
    color: $orange;
}
.mfp-iframe-holder .mfp-close {
    color: #646464;
}

.mfp-close-btn-in .mfp-close {
    opacity: 1;
    font-size: 70px;
    &:hover {
        opacity: 0.8;
    }
}

.popup-content {
    max-width: 1000px;
    background: #fff;
    margin: 0 auto;
    padding: 80px 20px;
    position: relative;

    @media (max-width: 1023px) {
        padding: 60px 0 40px;
    }

    .lead {
        font-size: 1.26em;
    }
    .banner {
        width: 100%;
        margin-bottom: 30px;
    }

    &.content--left-aligned {
        text-align: left;
        h1, h2, h3, h4, p {
            text-align: left;
        }
    }

    .row {
        margin-bottom: 20px;
        .one-half {
            img {
                width: 100%;
            }
        }
    }

    &.wide {
        max-width: 960px;

        .mfp-close {
            left: 70px;
            top: 40px;

            @media (max-width: 1023px) {
                left: 5px;
                top: 5px;
            }
        }
    }

    .mfp-close {
        position: absolute;
        top: 30px;
        left: 0;
        width: 22px;
        height: 23px;
        opacity: 1;

        background: {
            position: center center;
            repeat: no-repeat;
            size: 100% auto;
        }

        @media (max-width: 800px) {
            left: 5px;
        }
        @media (max-width: 1023px) {
            top: 5px;
        }
    }

    h1 {
        font-size: 52px;
        line-height: 0.9;
    }
    h2 {
        font-size: 31px;
    }

    h1, h2, h3, h4, p, ul, ol {
        color: $greytext;

        &.green {
            color: $green;
        }
        &.mediumblue {
            color: $mediumblue;
        }
        &.darkblue, &.blue {
            color: $darkblue;
        }
        &.altdarkblue {
            color: $altdarkblue;
        }
        &.lightblue {
            color: $lightblue;
        }
        &.orange {
            color: $orange;
        }
        &.yellow {
            color: $yellow;
        }
        &.grey {
            color: $greytext;
        }
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto 30px;
    }

    p, ul, ol {
        font-family: "Roboto Slab", sans-serif;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.582;
        color: $greytext;

        &.intro {
            font-size: 18px;
        }

        &.left {
            text-align: left;
        }

        strong {
            font-weight: 800;
        }
    }

    ul, ol {
        text-align: left;
    }

    .survey-results {
        @include clearfix;
        margin: 0 -63px;
        padding: 20px 0 35px;

        @media (max-width: 800px) {
            margin: 0;
        }

        ul {
            padding: 0;
            margin: 0 0 25px;
            font-family: "Roboto Slab", sans-serif;
            font-size: 16px;
            @include calc(width, '(100% - 25px) / 2');
            float: left;
            &:nth-child(2n) {
                margin-left: 25px;
            }

            @media (max-width: 1023px) {
                width: 100%;
                float: none;

                &:nth-child(2n) {
                    margin-left: 0;
                }

                &:first-child {
                    margin-bottom: 0;
                }
            }

            li {
                padding: 0;
                margin: 0;
                font-weight: 800;
                list-style: none;
                position: relative;
                @include clearfix;

                .bullet {
                    display: block;
                    float: left;
                    width: 42px;
                    height: 42px;
                    background: {
                        position: center center;
                        repeat: no-repeat;
                        size: 100% auto;
                    };
                }

                .content {
                    @include calc(width, '100% - 57px');
                    position: absolute;
                    right: 0;
                    top: 50%;
                    padding: 10px 0 10px;
                    margin: 0 0 0 15px;
                    box-sizing: content-box;
                    @include transform(translateY(-50%));
                }

                &:last-child {
                    .content {
                        border-bottom: 0;
                    }
                }

                &.separator {
                    border-bottom: 1px dashed;
                    margin: 10px 0 10px 55px;

                    &.mobile-only {
                        display: none;
                    }

                    @media (max-width: 1023px) {
                        margin: 15px 0 15px 55px;

                        &.mobile-only {
                            display: block;
                        }
                    }
                }
            }

            &.green {
                .bullet {
                    background-image: url(../images/popups/tick-green.png);
                }

                .separator {
                    border-bottom-color: lighten($green, 25%);
                }
            }
            &.lightblue {
                .bullet {
                    background-image: url(../images/popups/tick-lightblue.png);
                }

                .separator {
                    border-bottom-color: lighten($lightblue, 25%);
                }
            }
            &.blue {
                .bullet {
                    background-image: url(../images/popups/tick-blue.png);
                }

                .separator {
                    border-bottom-color: lighten($blue, 25%);
                }
            }
            &.orange {
                .bullet {
                    background-image: url(../images/popups/tick-orange.png);
                }

                .separator {
                    border-bottom-color: lighten($orange, 25%);
                }
            }
            &.yellow {
                .bullet {
                    background-image: url(../images/popups/tick-yellow.png);
                }

                .separator {
                    border-bottom-color: lighten($yellow, 25%);
                }
            }
        }
    }

    .btn {
        color: #fff;
        font-size: 23px;
        padding: 9px 25px;
        margin-bottom: 25px;

        &.green {
            background-color: $green;
        }
        &.mediumblue {
            background-color: $mediumblue;
        }
        &.darkblue {
            background-color: $darkblue;
        }
        &.lightblue {
            background-color: $lightblue;
        }
        &.orange {
            background-color: $orange;
        }
        &.yellow {
            background-color: $yellow;
        }
        &.grey {
            background-color: $greytext;
        }
    }

    .items {
        & > *:last-child {
            .item, &.item {
                margin-bottom: 0 !important;
            }

            .content {
                border-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        .item {
            margin-bottom: 30px;
            @include clearfix;

            .number {
                display: block;
                font-size: 86px;
                float: left;
                width: 90px;
                text-align: center;
                font-weight: 800;
                letter-spacing: -5px;
                line-height: 0.9;
                font-family: "Open Sans", sans-serif;

                &.green {
                    color: $green;
                }
                &.mediumblue {
                    color: $mediumblue;
                }
                &.darkblue {
                    color: $darkblue;
                }
                &.lightblue {
                    color: $lightblue;
                }
                &.orange {
                    color: $orange;
                }
                &.yellow {
                    color: $yellow;
                }
                &.grey {
                    color: $greytext;
                }
            }

            .content {
                float: right;
                @include calc(width, '100% - 115px');
                padding-bottom: 30px;
                border-bottom: 1px dashed #8b8b8b;

                *:last-child {
                    margin-bottom: 0;
                }
            }

            h2 {
                margin-bottom: 10px;
            }
        }
    }

    .cta-wrapper {
        margin: 0;

        &.left {
            text-align: left;
        }
        &.center {
            text-align: center;
        }
        &.right {
            text-align: right;
        }
    }

    .main-content {
        background: #f0f0f0;
        padding: 60px 0;

        .inner {
            max-width: 675px;
            margin: 0 auto;

            img {
                display: block;
                margin: 55px auto;
            }
        }

        & > .popup-text, & > .cta-wrapper, & > .image-wrapper, & > .two-col, & > .items, & > .tick-list {
            display: block;
            padding: 0 60px;
        }
        & > .cms-plugin {
            & > .popup-text, & > .cta-wrapper, & > .image-wrapper, & > .two-col, & > .items, & > .tick-list {
                display: block;
                padding: 0 60px;
            }
        }

        & + * {
            display: block;
            margin-top: 40px;
        }
    }

    .container > img {
        display: block;
        margin: 0 auto 40px;
    }

    &.blue-close {
        .mfp-close {
            color: $mediumblue;
            left: 10px;
        }
    }

    &.story {
        .story__header + .main-content {
            padding-top: 40px;
        }
    }

    .favourites-images {
        margin-bottom: 30px;
        @include clearfix;

        img {
            float: left;
            width: 50%;
            margin: 0;

            @media (max-width: 767px) {
                width: 100%;
                float: none;
            }
        }
    }
    .main-content {
        & > .favourites-images:first-child, & > .cms-plugin:first-child .favourites-images {
            margin-top: -60px;
        }
    }
}

form.styled {

}

.postcode {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 15px;
    label {
        display: block;
        margin-bottom: 10px;
        width: 100%;
    }
    input {
        max-width: 150px;
        margin: 0 auto;
        color: #333333;
    }
}
.submit {
    text-align: center;
}
button[type='submit'] {
    background-color: transparent;
    border: 0;
    .btn-arrow {
        margin-left: 10px;
    }
}

/*
 * Buttons
 */



/*
 * Select
 */

.select {
    position: relative;
    display: inline-block;
    color: #fff;
    width: 100%;
}
.select select {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: .5rem 2.25rem .5rem 1rem;
    line-height: 1.5;
    color: #fff;
    background-color: transparent;
    border: 1px dashed #fff;
    border-radius: 0;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand{
        display:none;
    }
}
/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.select .arrow {
    position: absolute;
    top: 1px;
    right: 1px;
    display: inline-block;
    content: "";
    width: 2.25rem;
    @include calc(height, '100% - 2px');
    pointer-events: none;
    background: {
        color: #fac113;
        position: right center;
        repeat: no-repeat;
        size: cover;
        image: url(../images/select-bg.png)
    }
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
  background-color: #ddd;
}
*/

/* Focus */
.select select:focus {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #fff;
    color: #000;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
    display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
    /* Firefox hack to hide the arrow */
    .select select {
        text-indent: 0.01px;
        text-overflow: '';
        padding-right: 1rem;
    }

    /* <option> elements inherit styles from <select>, so reset them. */
    .select option {
        background-color: #fff;
    }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
    .select select {
        padding-right: 0;
    }
    .select select:hover,
    .select select:focus,
    .select select:active {
        color: #555;
        background-color: transparent;
    }
}

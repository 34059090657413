

.job {
  border-bottom: 1px dashed $jobs;
  padding: 30px 0;
  position: relative;

  &:last-child {
    border: 0;
  }

  h1, h2 {
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  time {
    display: block;
    margin-bottom: 1em;
  }
  > p {
    //color: #646464;
    margin-bottom: 1.8em;
  }



  &--spec{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 0 1.2em 0;

    @include respond-max($breakpoint-sm-lower) {
      flex-wrap: wrap;
    }

    li {
      border-left: 2px solid $jobs;
      padding-left: 15px;
      margin-right: auto;

      @include respond-max($breakpoint-sm-lower) {
        margin: 0 0 25px;
        width: 50%;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }
  p a {
    color: $jobs;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .btn {
    display: none;
  }

  &.job--list-item {
    @include respond-min($breakpoint-sm) {
      .btn {
        display: inline-block;
        position: absolute;
        top: 60px;
        right: 0;
      }

      .job--info {
        width: calc(100% - 180px);
      }
    }
  }


  &.no-results {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 200px;
  }
}

.job-details {
  max-width: 1020px;
  margin: 0 auto;
  padding: 50px 20px 20px 20px;


  .job-details--header {
    padding: 15px;
    margin-bottom: 20px;
    h1, h2 {
      margin-bottom: 5px;
    }
  }

  img   {
    width: 100%;
  }

  .btn {
    display: inline-block;
  }

  .job--spec{
    display: block;
    list-style-type: none;
    padding: 0;

    @include respond-max($breakpoint-sm-lower) {
      flex-wrap: wrap;
    }
  }

  iframe {
    margin-bottom: 10px;
  }

  .job-details--sidebar {
    background: #efefef;
    padding: 15px;

    .addthis_toolbox {
      float: right;
    }
  }

  &.job .mfp-close {
    position: absolute;
    right: auto;
    font-size: 40px;
    top: 0px;
    left: 10px;
    color: $jobs;
  }
}

.featured .job {
  border-bottom: 0;
}

.job-apply {
    text-align: center;
}

/* 
 * 	MODIFIED Default theme - Owl Carousel CSS File
 */

.owl-stage-outer {
    z-index: 100;
}

.owl-theme .owl-nav {
    margin-top: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav  {
    @include respond-max($breakpoint-sm-lower) {
        display: none;
    }
    .owl-prev, .owl-next {
        background: url(../images/sprite.png) no-repeat 0 -736px;
        height: 45px;
        width: 45px;
        display: inline-block;
        cursor: pointer;
        position: absolute;
        left: 30px;
        top: 50%;
        margin-top: -22px;
        font-family: AdobeBlank, sans-serif;
        z-index: 100;

    }
    .owl-next {
        background-position: 0 -683px;
        left: auto;
        right: 30px;
    }
}

.perks-carousel.owl-theme, .variety-carousel.owl-theme {
    .owl-prev {
        left: -60px;
    }
    .owl-next {
        right: -60px;
    }
}


.owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }
.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px; }
.owl-theme .owl-dots {
    padding-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent; }
.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #fff;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease; }
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #646464; }


.slide {
    .full-width-image {
        width: 100%;
    }
}

.owl-carousel {
    .card {
        margin: 0 10px;
    }
}

.perks-carousel.owl-theme, .variety-carousel.owl-theme {
    .owl-prev {
        left: -60px;
    }
    .owl-next {
        right: -60px;
    }
}

.theme-carousel--blue.owl-theme {
    .owl-prev {
        left: -60px;
        background-position: 0 -630px;
    }
    .owl-next {
        right: -60px;
        background-position: 0 -573px;
    }
}
.footer {
    padding: 30px 0;
    @extend %clearfix;

    .footer-logo {
        display: block;
        float: left;
        padding: 0 15px 0 0;

        img {
            display: block;
            height: 40px;
            margin: 0 auto;
        }

        @include respond-max(840px) {
            float: none;
            width: 100%;
            padding-bottom: 8px;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: center;

        li {
            display: inline-block;

            a {
                border-right: 1px solid $midtext;
                color: $midtext;
                display: inline-block;
                line-height: 1;
                padding: 0 10px;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            &:first-child {
                a {
                    padding-left: 0;
                }
            }

            &:last-child {
                a {
                    border: 0;
                }
            }
        }
    }

    nav {
        float: left;
        padding-top: 8px;

        @include respond-max(840px) {
            float: none;
            padding-bottom: 8px;
        }
    }

    ul.social-links {
        float: right;

        li {
            margin-left: 32px;

            &:first-child {
                margin-left: 0;
            }
        }
        li a {
            display: block;
            border: 0;
            padding: 0;

            &:hover {
                opacity: 0.7;
            }
        }

        .icon-facebook {
            @include icon(-527px, 31px, 15px);
            background-position: -14px -527px;
        }
        .icon-twitter {
            @include icon(-532px, 31px, 33px);
            background-position: -61px -528px;
        }
        .icon-xing {
            @include icon(-579px, 33px, 28px);
            background-position: -64px -579px;
        }
        .icon-kununu {
            @include icon(-636px, 33px, 33px);
            background-position: -64px -636px;
        }

        @include respond-max(840px) {
            float: none;
            width: 100%;
            font-size: 0;
            text-align: center;

            li {
                display: inline-block;
            }
        }
    }
}

.facts-and-figures {
    margin-bottom: 40px;
    img {
        display: block;
        height: auto;
        margin: 0 auto;
    }
    div {
        width: 140px;
        height: 140px;
        border: 15px solid #fff;
        border-radius: 70px;
        margin: 0 auto;
        padding: 5px;

        p {
            position: relative;
            top: 50%;
            @include transform(translateY(-50%));
            @include opensans(300);
            font-size: 16px;
            text-transform: uppercase;
            line-height: 1;

            strong {
                display: block;
                @include opensans(800);
                font-size: 32px;
            }
        }
    }
}

.logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;
    img {
        display: inline-block;
    }
}

@include respond-min($breakpoint-sm) {

    .facts-and-figures {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 40px;
        width: 100%;
        img {
            height: auto;
            width: auto;
        }
    }

}